@import ./palette.module

/// Replace `$search` with `$replace` in `$string`
/// @author Kitty Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '')
  $index: str-index($string, $search)

  @if $index
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace)

  @return $string

@function get-color-value($color)
  @return #{unquote(str-replace(str-replace($color, 'rgb(', ''), ')', ''))}

@mixin extract-color-map($map)
  @each $key, $value in $map
    #{$key}: #{get-color-value($value)}

.theme-light
  @include extract-color-map($theme-light)

.theme-dark
  @include extract-color-map($theme-dark)
