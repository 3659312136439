$padding-mobile: theme("padding.4");
$padding-desktop: theme("padding.6");

$header-height: theme("height.12");
$header-z: theme("zIndex.30");

$sidebar__hamburger-width: theme("width.10");

$modal-padding-x: theme("padding.6");
$modal-padding-x-desktop: theme("padding.12");

$max-width-lg: 936px;
