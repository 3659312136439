@import "styles/common/spec-common";

$sidebar_animation_duration: theme("transitionDuration.200");
$sidebar-item-height: theme("height.14");
$sidebar-width--collapsed: 3.25rem;
$sidebar-width: theme("width.64");

.sidebar__item {
  @apply text-skin-base text-opacity-60;
  @apply flex items-center;
  @apply duration-100;
  @apply px-5;
  height: $sidebar-item-height;
  width: $sidebar-width;

  &:hover {
    @apply bg-skin-base bg-opacity-5;
  }

  &--active {
    @apply text-skin-secondary;
  }

  &:focus {
    @apply outline-skin-secondary;
  }

  > *:first-child {
    @apply mr-4;
  }

  > * {
    @apply text-sm font-medium;
  }
}

.sidebar__cta {
  @apply justify-start;
  @apply rounded-none;
  @apply w-full;
  @apply px-5;
  height: $sidebar-item-height;

  > .dd-icon {
    @apply mr-4;
  }

  &--expand-cta {
    @apply justify-end;
    width: $sidebar-width--collapsed;

    > .dd-icon {
      @apply transform rotate-180;
      @apply duration-200;
      @apply mr-0;
    }

    &--expanded {
      @apply w-full;

      > .dd-icon {
        @apply transform rotate-0;
      }
    }
  }

  &--show-user {
    @apply flex;
    @apply items-center border-b; 
  }
}

.sidebar {
  @apply transform -translate-x-full;
  @apply fixed top-0 left-0;
  @apply overflow-x-hidden;
  @apply flex flex-col;
  @apply bg-skin-top;
  @apply shadow-md;
  @apply h-screen;
  @apply z-40;
  width: $sidebar-width--collapsed;
  grid-area: sidebar;
  transition-duration: $sidebar_animation_duration;

  @screen lg {
    @apply sticky left-auto;
    @apply translate-x-0;
  }

  &--active {
    @apply transform translate-x-0;
    @screen lg {
      @apply w-64;
    }
  }

  &--expanded {
    width: $sidebar-width;

    > .main-content > .sidebar__group > .sidebar__group-title {
      @apply flex;
    }
  }

  > .logo-container {
    @apply flex items-center;
    @apply shadow-md;
    padding-left: 0.7rem; // No good px-* for this
    padding-right: 0.7rem;
    min-height: $header-height;
    max-height: $header-height;
    width: $sidebar-width;

    > .app-logo {
      @apply h-10;
    }
  }

  > .bottom-container {
    @apply border-t border-skin-disabled;
    @apply mt-auto;
    width: $sidebar-width;

    > .sidebar__cta--expand-cta {
      @apply mt-8;
    }
  }

  > .main-content,
  > .bottom-container {
    > .sidebar__group {
      @apply border-b-2 border-skin-disabled border-opacity-50;
      @apply pb-2;

      > .sidebar__group-title {
        @apply flex justify-start items-center;
        @apply font-semibold;
        @apply px-5;
        height: $sidebar-item-height;
        width: $sidebar-width;

        &--hidden {
          @apply hidden;
        }
      }
    }
  }

  > .main-content {
    @apply overflow-y-auto overflow-x-hidden;
    @apply grow;
  }

  .language-text {
    @apply font-medium;
    @apply w-5;
  }
}

.sidebar__hamburger {
  @apply fixed top-0;
  transition-duration: $sidebar_animation_duration;
  width: $sidebar__hamburger-width;
  height: $header-height;
  z-index: calc($header-z + 1);

  @screen lg {
    @apply hidden;
  }

  &--hide {
    @apply pointer-events-none;
    @apply opacity-0;
  }
}

.sidebar__overlay {
  @apply bg-black bg-opacity-40;
  @apply fixed inset-0;
  @apply z-40;
  transition-duration: $sidebar_animation_duration;

  &--enter {
    &-from {
      @apply opacity-0;
    }
    &-to {
      @apply opacity-100;
    }
  }
  &--leave {
    &-from {
      @apply opacity-100;
    }
    &-to {
      @apply opacity-0;
    }
  }
}
